(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
window.$ = window.JQuery = window.jQuery = global.jQuery = require('jquery')
require('jquery-confirm')
require('jquery-validation')
require('jquery-price-format')
require('jquery-mask-plugin/dist/jquery.mask.min.js')
require('bootstrap')
require('bootstrap-select/js/bootstrap-select')
window.Slider = require('bootstrap-slider')
require('daterangepicker')
require('typeahead')
require('slick-carousel')

window.moment = require('moment')
window.moment.locale = require('moment/locale/ru')
window.moment.timezone = require('moment-timezone')
window.moment.tz.setDefault("Europe/Moscow")

window.Clipboard = require('clipboard')
// window.Chart = require('chart.js/dist/Chart.bundle.min.js')
window.NProgress = require('nprogress')
window.Bloodhound = require('bloodhound-js');
window.autosize = require('autosize');

window.getUrlParam = function getUrlParam(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;
    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
};
window.updateUrlParam = function updateUrlParam(key, value) {
    baseUrl = [location.protocol, '//', location.host, location.pathname].join('');
    urlQueryString = document.location.search;
    var newParam = key + '=' + value,
    params = '?' + newParam;
    // If the "search" string exists, then build params from it
    if (urlQueryString) {
        keyRegex = new RegExp('([\?&])' + key + '[^&]*');
    // If param exists already, update it
    if (urlQueryString.match(keyRegex) !== null) {
        params = urlQueryString.replace(keyRegex, "$1" + newParam);
        } else { // Otherwise, add it to end of query string
        params = urlQueryString + '&' + newParam;
        }
    }
    window.history.replaceState({}, "", baseUrl + params);
}
window.removeUrlParam = function removeUrlParam(key, sourceURL) {
    var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split("=")[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
}

window.searchToObject = function searchToObject() {
    var pairs = window.location.search.substring(1).split("&"),
      obj = {},
      pair,
      i;

    for ( i in pairs ) {
      if ( pairs[i] === "" ) continue;

      pair = pairs[i].split("=");
      obj[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
    }

    return obj;
}

window.transliterate = function transliterate(str) {
    str = str.toLowerCase(); // все в нижний регистр
    let cyr2latChars = [['а', 'a'], ['б', 'b'], ['в', 'v'], ['г', 'g'],
            ['д', 'd'],  ['е', 'e'], ['ё', 'yo'], ['ж', 'zh'], ['з', 'z'],
            ['и', 'i'], ['й', 'y'], ['к', 'k'], ['л', 'l'],
            ['м', 'm'],  ['н', 'n'], ['о', 'o'], ['п', 'p'],  ['р', 'r'],
            ['с', 's'], ['т', 't'], ['у', 'u'], ['ф', 'f'],
            ['х', 'h'],  ['ц', 'c'], ['ч', 'ch'],['ш', 'sh'], ['щ', 'shch'],
            ['ъ', ''],  ['ы', 'y'], ['ь', ''],  ['э', 'e'], ['ю', 'yu'], ['я', 'ya'],
            ['А', 'A'], ['Б', 'B'],  ['В', 'V'], ['Г', 'G'],
            ['Д', 'D'], ['Е', 'E'], ['Ё', 'YO'],  ['Ж', 'ZH'], ['З', 'Z'],
            ['И', 'I'], ['Й', 'Y'],  ['К', 'K'], ['Л', 'L'],
            ['М', 'M'], ['Н', 'N'], ['О', 'O'],  ['П', 'P'],  ['Р', 'R'],
            ['С', 'S'], ['Т', 'T'],  ['У', 'U'], ['Ф', 'F'],
            ['Х', 'H'], ['Ц', 'C'], ['Ч', 'CH'], ['Ш', 'SH'], ['Щ', 'SHCH'],
            ['Ъ', ''],  ['Ы', 'Y'],
            ['Ь', ''],
            ['Э', 'E'],
            ['Ю', 'YU'],
            ['Я', 'YA'],
            ['a', 'a'], ['b', 'b'], ['c', 'c'], ['d', 'd'], ['e', 'e'],
            ['f', 'f'], ['g', 'g'], ['h', 'h'], ['i', 'i'], ['j', 'j'],
            ['k', 'k'], ['l', 'l'], ['m', 'm'], ['n', 'n'], ['o', 'o'],
            ['p', 'p'], ['q', 'q'], ['r', 'r'], ['s', 's'], ['t', 't'],
            ['u', 'u'], ['v', 'v'], ['w', 'w'], ['x', 'x'], ['y', 'y'],
            ['z', 'z'],
            ['A', 'A'], ['B', 'B'], ['C', 'C'], ['D', 'D'],['E', 'E'],
            ['F', 'F'],['G', 'G'],['H', 'H'],['I', 'I'],['J', 'J'],['K', 'K'],
            ['L', 'L'], ['M', 'M'], ['N', 'N'], ['O', 'O'],['P', 'P'],
            ['Q', 'Q'],['R', 'R'],['S', 'S'],['T', 'T'],['U', 'U'],['V', 'V'],
            ['W', 'W'], ['X', 'X'], ['Y', 'Y'], ['Z', 'Z'],
            [' ', '-'],['0', '0'],['1', '1'],['2', '2'],['3', '3'],
            ['4', '4'],['5', '5'],['6', '6'],['7', '7'],['8', '8'],['9', '9'],
            ['-', '-']];

    let newStr = String();
    for (let i = 0; i < str.length; i++) {
        let ch = str.charAt(i);
        let newCh = '';
        for (let j = 0; j < cyr2latChars.length; j++) {
            if (ch === cyr2latChars[j][0]) {
                newCh = cyr2latChars[j][1];
            }
        }
        newStr += newCh;
    }
    return newStr.replace(/[_]{2,}/gim, '_').replace(/\n/gim, '');
};

window.sendPostAjaxRedirect = function sendPostAjaxRedirect(url, redirect) {
    $.post(url);
    window.location.href = redirect;
};

window.modalInfo = function modalInfo(title, content) {
    $.confirm({
        theme: 'supervan',
        columnClass: 'medium',
        backgroundDismiss: true,
        title: title,
        content: content,
        buttons: {
            cancel: {
                btnClass: 'btn-default',
                text: 'ОК'
            }
        }
    })
};

window.tinyMceInit = function tinyMceInit(element, height, toolbar) {
    tinymce.init({
        mode : "exact",
        selector:element,
        plugins: "link, lists, visualblocks, paste",
        toolbar: [toolbar],
        rel_list: [
            {title: 'no-follow', value: 'nofollow'},
            {title: 'follow', value: 'follow'}
        ],
        style_formats: [
            { 
                title: 'Заголовки', items: [
                    { title: 'h1', block: 'h1' },
                    { title: 'h2', block: 'h2' },
                    { title: 'h3', block: 'h3' },
                    { title: 'h4', block: 'h4' },
                ] 
            },

            { 
                title: 'Текст', items: [
                    { title: 'p', block: 'p' }
                ] 
            }
        ],
        menubar:false,
        statusbar: false,
        height: height,
        relative_urls: false,
        visualblocks_default_state: true,
        end_container_on_empty_block: true,
        paste_auto_cleanup_on_paste : true,
        paste_remove_styles: true,
        paste_remove_styles_if_webkit: true,
        paste_strip_class_attributes: true,
    }); 
}

window.buttonLabelAttachFile = function (element) {
    let file_elem_id = $(element).attr('id');
    let file_name = $(element)[0].files[0].name;

    $(document).find('[for='+ file_elem_id +']').find('span').text('Файл - ' + file_name);
}

window.modalDelete = function modalDelete(element) {
    let delete_url = $(element).attr('data-delete-url');
    let redirect_url = $(element).attr('data-delete-redirect');

    $.confirm({
        theme: 'supervan',
        columnClass: 'medium',
        backgroundDismiss: true,
        title: 'Удаление',
        content: 'Вы уверены?',
        buttons: {
            ok: {
                btnClass: 'btn-danger',
                text: 'Удалить',
                action: function () {
                    $.ajax({
                        url: delete_url,
                        method: 'POST',
                        async: false,
                        success: function (resp) {
                            if (resp.status == 200) {
                                window.location.href = redirect_url;
                            } else {
                                modalInfo('Ошибка', resp.message);
                            }
                        }
                    });
                }
            },
            cancel: {
                btnClass: 'btn-default',
                text: 'Отмена',
                action: function () {

                }
            }
        }
    });
}

window.init_masks = function init_masks() {
    $('[type=tel]').mask('+7 (000) 000-00-00');
    $('[type=date_m-y]').mask('00.0000');
    $('[type=year]').mask('0000');
    $('[type=days]').mask('000');
    $('[type=num]').mask('000000000000');
    $('[type=longNum]').mask('000000000000000000000000000000');
    $('[type=passport_num_ser]').mask('0000 000000');
    $('[type=passport_code_dep]').mask('000-000');
    $('[type=date_picker]').mask('00.00.0000');

    $('[type=hours]').mask('00', {
        onKeyPress: function(value, e, field, options) {
            if (value > 23) {
                $(field).val(23);
            }
        }
    });
    $('[type=minutes]').mask('00', {
        onKeyPress: function(value, e, field, options) {
            if (value > 59) {
                $(field).val(59);
            }
        }
    });

    $('[type=price]').priceFormat({
        prefix: '',
        centsSeparator: '',
        thousandsSeparator: ' ',
        centsLimit: 0
    });
    $('[type=price_cents]').priceFormat({
        prefix: '',
        centsSeparator: '.',
        thousandsSeparator: ' ',
        centsLimit: 2
    });
};

window.initializeRangePicker = function initializeRangePicker(val, slidernum, width, height) {
        
    var thumb = document.getElementById("sliderthumb" + slidernum);
    var shell = document.getElementById("slidershell" + slidernum);
    var track = document.getElementById("slidertrack" + slidernum);
    var fill = document.getElementById("sliderfill" + slidernum);
    var rangevalue = document.getElementById("slidervalue" + slidernum);
    var slider = document.getElementById("slider" + slidernum);
    
    var pc = val/(slider.max - slider.min);
    var bigval = width;
    var fillsize = height;
    var thumbsize = 30;
    var smallval = 30;
    var tracksize = bigval - thumbsize;
    var filloffset = 13;
    var loc = pc * tracksize;
    
    rangevalue.style.left = (loc - 62) + "px";
    thumb.style.left = (loc) + "px";
    fill.style.top = (filloffset) + "px";
    fill.style.width = (loc + (thumbsize/2)) + "px";
    fill.style.height = (fillsize) + "px";
    shell.style.height = (smallval) + "px";
    shell.style.width = (bigval) + "px";
    track.style.height = (fillsize) + "px";
    track.style.width = (bigval) + "px";
    track.style.top = (filloffset) + "px";
}

window.livePageSearch = function livePageSearch(value, string_dom, string_parent) {
    $(string_dom).each(function () {
        if ($(this).text().search(new RegExp(value, 'i')) < 0) {
            $(this).closest(string_parent).fadeOut();
        } else {
            $(this).closest(string_parent).show();
        }
    });
}

$(document).ready(function () {
    $(document).on('click', '#open_mobile_menu', function(e) {
        e.preventDefault();
        $(document).find('.mobile_menu_draggable').animate({
            left: "+=400",
        }, 500);
    });
    $(document).on('click', '#open_mobile_menu_search', function (e) {
        e.preventDefault();
        $(document).find('.mobile_menu_draggable').animate({
            left: "+=400",
        }, 500);
        $(document).find('.mobile_menu_draggable').find('[name=system_search_query]').focus();
    });
    $(document).on('click', '#close_mobile_menu', function (e) {
        e.preventDefault();
        $(document).find('.mobile_menu_draggable').animate({
            left: "-=400",
        }, 500);
    });

    $(document).on('click', '#open_mobile_sub_menu', function (e) {
        $(document).find('.mobile_sub_menu').find('.mobile_sub_menu_items').toggle();
    });
});

window.onload = function () {
    autosize($('textarea'));
    init_masks();

    jQuery.extend(jQuery.validator.messages, {
        required: "This field is required.",
        remote: "Please fix this field.",
        email: "Введите корректный e-mail адрес",
        url: "Please enter a valid URL.",
        date: "Please enter a valid date.",
        dateISO: "Please enter a valid date (ISO).",
        number: "Please enter a valid number.",
        digits: "Please enter only digits.",
        creditcard: "Please enter a valid credit card number.",
        equalTo: "Please enter the same value again.",
        accept: "Please enter a value with a valid extension.",
        maxlength: jQuery.validator.format("Please enter no more than {0} characters."),
        minlength: jQuery.validator.format("Please enter at least {0} characters."),
        rangelength: jQuery.validator.format("Please enter a value between {0} and {1} characters long."),
        range: jQuery.validator.format("Please enter a value between {0} and {1}."),
        max: jQuery.validator.format("Please enter a value less than or equal to {0}."),
        min: jQuery.validator.format("Please enter a value greater than or equal to {0}.")
    });

    $(document).on('click', '#open_modal_recal', function () {
        $(document).find('.modal_recal').show();
        $(document).find('.modal_recal').prev().show();
    });
    $(document).on('click', '#hide_modal', function () {
        $(this).closest('.modal').hide();
        $(this).closest('.modal').prev().hide();
    });
    $(document).on('click', '.overlay', function () {
        $(this).hide();
        $(this).next().hide();
    });

    $(document).on('click', '#button_modal_delete', function () {
        modalDelete($(this));
    });

    $('[type=date_picker]').daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        autoApply: true,
        autoUpdateInput: false,
        locale: {
            format: 'DD.MM.YYYY'
        }
    }).on('apply.daterangepicker', function (ev, picker) {
        let value = picker.startDate.format('DD.MM.YYYY');
        $(this).val(value)
    });

    $('[data-sort-field]').click(function () {
        let param = $(this).attr('data-sort-field');

        $('[data-sort-field]').each(function () {
            let href_without_params = removeUrlParam($(this).attr('data-sort-field'), window.location.href)
            if (param != $(this).attr('data-sort-field')) {
                window.history.replaceState({}, "", href_without_params);
            }
        });

        let param_value = getUrlParam(param);
        if (param_value) {
            switch(param_value) {
                case 'DESC':
                    updateUrlParam(param, 'ASC');
                    break;
                case 'ASC':
                    updateUrlParam(param, 'DESC');
                    break;
            }
        } else {
            updateUrlParam(param, 'ASC');
        }
        window.location.reload();
    });
    $('[data-sort-field]').each(function (e) {
        let param = $(this).attr('data-sort-field');
        let param_value = getUrlParam(param);
        if (param_value) {
            $(this).addClass('active');
        }
    });
};
}).call(this,require("FT5ORs"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/fake_36653540.js","/")